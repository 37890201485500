import React from "react";
import { SelectRow } from "./ProductParam";

class SizeConfig extends React.Component {
  render() {
    const { product, config } = this.props;

    return (
      <>
        {this._renderSizeSelect({ product, config, select: "style_select" })}
        {this._renderSizeSelect({ product, config, select: "material_select" })}
        {this._renderSizeSelect({ product, config, select: "size_select" })}
      </>
    );
  }

  _renderSizeSelect = ({ product, config, select }) => {
    if (!product[select] || !config[select]) {
      return null;
    } else if (product[select].type === "master") {
      return this._renderMasterSelectSize(config[select]);
    }

    const option = product[select].options.find(o => o.name === config[select]);

    if (!option) {
      return null;
    }

    return (
      <>
        {select === "style_select" && (
          <>
            {this._renderField({
              option,
              field: "product_size",
              label: "成品尺寸",
            })}
            {this._renderField({
              option,
              field: "product_img_size",
              label: "圖面尺寸",
            })}
            {this._renderField({
              option,
              field: "product_capacity",
              label: "容量",
            })}
          </>
        )}

        {select === "material_select" && (
          <>
            {this._renderField({
              option,
              field: "finished_prod_size",
              label: "成品尺寸",
            })}
            {this._renderField({
              option,
              field: "bleeding_size",
              label: "出血尺寸",
            })}
            {this._renderField({
              option,
              field: "finished_prod_thickness",
              label: "厚度",
            })}
          </>
        )}

        {select === "size_select" && (
          <>
            {this._renderField({
              option,
              field: "product_size",
              label: "成品尺寸",
            })}
            {this._renderField({
              option,
              field: "bleed_size",
              label: "出血尺寸",
            })}
            {this._renderField({
              option,
              field: "bleeding_size",
              label: "出血尺寸",
            })}
          </>
        )}
      </>
    );
  };

  _renderMasterSelectSize = selectedOption => {
    const sizeInfo = MASTER_SIZE_TABLE[selectedOption.size];

    if (!sizeInfo) {
      return null;
    }

    return (
      <>
        {/* <div
          style={{
            display: "flex",
            alignItems: "baseline",
            padding: "10px 0px",
          }}
        >
          <label>倍數</label>
          <div>{` ${sizeInfo.scale} `}</div>
          <div></div>
        </div> */}
        <SelectRow style={{ alignItems: "baseline" }}>
          <label>成品尺寸</label>
          <div className="select-options ">{` ${sizeInfo.productSize} `}</div>
        </SelectRow>

        <SelectRow style={{ alignItems: "baseline" }}>
          <label>出血尺寸</label>
          <div className="select-options ">{` ${sizeInfo.bleedSize} `}</div>
        </SelectRow>
      </>
    );
  };

  _renderField = ({ option, field, label }) => {
    if (!option[field]) {
      return null;
    }

    return (
      <SelectRow style={{ alignItems: "baseline" }}>
        <label>{label}</label>
        <div className="select-options ">{` ${option[field]} `}</div>
      </SelectRow>
    );
  };
}

const MASTER_SIZE_TABLE = {
  A2: {
    scale: 1,
    productSize: "594 x 420mm",
    bleedSize: "596 x 422mm",
  },
  A3: {
    scale: 1,
    productSize: "420 x 297mm",
    bleedSize: "426 x 303mm",
  },
  A4: {
    scale: 1,
    productSize: "297 x 210mm",
    bleedSize: "303 x 216mm",
  },
  A5: {
    scale: 1,
    productSize: "210 x 148mm",
    bleedSize: "216 x 154mm",
  },
  長A2: {
    scale: 1,
    productSize: "840 x 297mm",
    bleedSize: "846 x 303mm",
  },
  長A3: {
    scale: 1,
    productSize: "594 x 210mm",
    bleedSize: "600 x 216mm",
  },
  GK2: {
    scale: 1,
    productSize: "594 x 420mm",
    bleedSize: "596 x 422mm",
  },
  GK3: {
    scale: 1,
    productSize: "630 x 297mm",
    bleedSize: "636 x 303mm",
  },
  歐2K: {
    scale: 1,
    productSize: "728 x 502mm",
    bleedSize: "732 x 506mm",
  },
  A1尺寸: {
    scale: 1,
    productSize: "84.1 x 59.4cm",
    bleedSize: null,
  },
  A2尺寸: {
    scale: 1,
    productSize: "59.4 x 42cm",
    bleedSize: null,
  },
  A3尺寸: {
    scale: 1,
    productSize: "42 x 29.7cm",
    bleedSize: null,
  },
  "50 x 70": {
    scale: 1,
    productSize: "50 x 70cm",
    bleedSize: null,
  },
  "70 x 100": {
    scale: 1,
    productSize: "70 x 100cm",
    bleedSize: null,
  },
};

export default SizeConfig;
