import React, { Fragment } from "react";
import styled from "styled-components";
import * as Theme from "../Theme";

function getColor(tag) {
  let color = Theme.colors.orangeRed;
  const dic = {
    is_out_stock: "#F0755F",
    is_period: "#a1be8f",
    HOT: Theme.colors.main,
    SALE: Theme.colors.brown,
    NEW: Theme.colors.blueGreen,
  };
  return dic[tag] || color;
}

export default function ProductTag({
  product,
  freeShipping = false,
  isPeriod = false,
  mobile = false,
  horizontalMode = false,
  onItem,
}) {
  const Tag = onItem ? TagOnItem : TagInDetail;

  if (!product || horizontalMode) {
    return null;
  }
  const outOfStock = product.is_out_stock;
  let tags = [];

  if (onItem) {
    // only one
    if (outOfStock) {
      tags = [
        <Tag color={getColor("is_out_stock")} isMobile={mobile}>
          售完
        </Tag>,
      ];
    } else if (isPeriod) {
      tags = [
        <Tag color={getColor("is_period")} isMobile={mobile}>
          訂閱
        </Tag>,
      ];
    } else if (freeShipping) {
      tags = [
        <Tag color={getColor("")} isMobile={mobile}>
          免運
        </Tag>,
      ];
    } else if (product.tag) {
      tags = [
        <Tag color={getColor(product.tag)} isMobile={mobile}>
          {product.tag}
        </Tag>,
      ];
    }
  } else {
    // all
    if (outOfStock) {
      tags.push(
        <Tag color={getColor("is_out_stock")} isMobile={mobile}>
          售完
        </Tag>
      );
    }
    if (isPeriod) {
      tags.push(
        <Tag color={getColor("is_period")} isMobile={mobile}>
          訂閱
        </Tag>
      );
    }
    if (freeShipping) {
      tags.push(
        <Tag color={getColor("")} isMobile={mobile}>
          免運
        </Tag>
      );
    }
    if (product.tag) {
      tags.push(
        <Tag color={getColor(product.tag)} isMobile={mobile}>
          {product.tag}
        </Tag>
      );
    }
  }

  return tags.length > 0 ? tags : null;
}

const TagOnItem = styled.div`
  z-index: 2;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: ${props => (props.isMobile ? "-17px" : "-30px")};
  top: ${props => (props.isMobile ? "-17px" : "-30px")};
  width: ${props => (props.isMobile ? "35px" : "60px")};
  height: ${props => (props.isMobile ? "35px" : "60px")};
  font-size: ${props => (props.isMobile ? "11px" : "16px")};
  color: white;
  border-radius: 50px;
  background-color: ${props => props.color};
`;

export const TagInDetail = styled.div`
  margin-left: 7px;
  width: 44px;
  height: 44px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  line-height: 44px; /*the same as the div height*/
  color: white;
  border-radius: 50%;
  background-color: ${props => props.color};
  font-size: 15px;
`;
