import React, { Component } from "react";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import ReactPlayer from "react-player";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";

import * as Theme from "../Theme";

const RATIO = 500 / 765;

class ProductImageCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      slideIndex: 0,

      carouselWidth: "100%",
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });

    if (typeof window != undefined) {
      this.setState({ carouselWidth: window.innerWidth - 20 * 2 });
    }
  }

  render() {
    let { video, imagesArray } = this.props;
    let { slideIndex, mounted, carouselWidth } = this.state;

    if (!mounted || imagesArray.length === 0) {
      return (
        <div style={{ width: "100%", height: 300, backgroundColor: "grey" }} />
      );
    }

    return (
      <div
        style={
          carouselWidth !== "100%" && carouselWidth <= Theme.breakpoints.xs
            ? { width: carouselWidth }
            : {}
        }
      >
        <Carousel
          autoplay={!video && imagesArray.length > 1 ? true : false}
          disableAnimation={video || imagesArray.length === 1}
          initialSlideHeight={500}
          renderBottomRightControls={props => this._renderDotControls(props)}
          renderBottomCenterControls={() => false}
          renderCenterLeftControls={props => this._renderLeftIcon(props)}
          renderCenterRightControls={props => this._renderRightIcon(props)}
          slideIndex={slideIndex}
          afterSlide={slideIndex => this.setState({ slideIndex })}
          speed={500}
          wrapAround
        >
          {video && (
            <ImageContainer>
              <ReactPlayer
                url={video.video_link}
                width={"100%"}
                height={"100%"}
                playing={slideIndex === 0 ? true : false}
              />
            </ImageContainer>
          )}
          {imagesArray
            .filter(image => image.name == "top")
            .map((d, i) => (
              <ImageContainer key={"carousel-image" + i}>
                {/* <Image src={d.image} css="width: 100%;" /> */}
                <img src={d.image} style={{ width: "100%" }} />
              </ImageContainer>
            ))}
        </Carousel>
      </div>
    );
  }

  _renderLeftIcon = ({ previousSlide }) => (
    <IconContainer onClick={previousSlide}>
      <LeftCircleOutlined style={{ color: "white", fontSize: 30 }} />
    </IconContainer>
  );

  _renderRightIcon = ({ nextSlide }) => (
    <IconContainer onClick={nextSlide}>
      <RightCircleOutlined style={{ color: "white", fontSize: 30 }} />
    </IconContainer>
  );
  _renderDotControls = ({ currentSlide, slideCount }) => {
    return (
      <div
        className="slider-control"
        style={{
          position: "absolute",
          bottom: "0px",
          right: "20px",
        }}
      >
        <ul
          style={{
            position: "relative",
            margin: "0px",
            top: "-20px",
            padding: "0px",
            display: "flex",
          }}
        >
          {[...Array(slideCount)].map((sc, i) => (
            <li
              style={{ listStyleType: "none", display: "inline-block" }}
              key={i + 1}
            >
              <div
                style={{
                  width: "8px",
                  height: "8px",
                  background: currentSlide === i ? "#3c3c3c" : "#f6f6f6",
                  borderRadius: "4px",
                  margin: "0 7px",
                }}
              ></div>
            </li>
          ))}
        </ul>
      </div>
    );
  };
}

const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border: 0px;
  background-color: #0005;
  width: 50px;
  height: 50px;

  opacity: 0;

  &:hover {
    opacity: 1;
  }

  :focus {
    outline: none;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  padding-bottom: ${RATIO * 100}%;
  overflow: hidden;

  & > img {
    width: 100%;
    position: absolute;
    top: 0;
  }
`;

export default ProductImageCarousel;
