import React from "react";
import styled from "styled-components";
import * as Ant from "antd";
import * as Theme from "../Theme";
import Image from "./ImageBackground";
import useDimension from "../hooks/use-dimension";
import Tag from "./ProductTag";

function ProductItem(props) {
  const { rwd, dimension } = useDimension();
  const pad = dimension.innerWidth <= Theme.breakpoints.lg;
  const mobile = dimension.innerWidth <= Theme.breakpoints.xs;

  const { product, spec, extraCss = "", horizontalMode, link = "#" } = props;
  let css = `${extraCss}width: calc(22% - 10px);@media screen and (max-width:${Theme.breakpoints.lg}px) {width: calc(45% - 10px);}@media screen and (max-width:${Theme.breakpoints.xs}px) {width: calc(49% - 10px);}`;
  if (!product) {
    return <Wrapper css={css + "opacity: 0; height: 0px;"}></Wrapper>;
  }
  const freeShipping = spec && spec.free_shipping;

  return (
    <Wrapper
      isPad={pad}
      onClick={props.onClick}
      css={!horizontalMode ? css : ""}
      horizontalMode={horizontalMode}
      data-delighter="start:0.95;end:0.95;"
      href={link}
    >
      <ImageWrapper horizontalMode={horizontalMode}>
        <div className="cover">
          <div className="price-block">
            <Price
              horizontalMode={horizontalMode}
              css={{ color: "#fff", fontSize: 18 }}
            >
              NT$ {product.price}
            </Price>
            {product.original_price !== product.price && (
              <OriginalPrice css={{ color: "#fff", fontSize: 15 }}>
                NT: {product.original_price}
              </OriginalPrice>
            )}
          </div>
        </div>
        {(product.image && (
          <Image
            src={product.image}
            extraStyle={{
              backgroundColor: "#e0e0e0",
              height: "100%",
              width: "100%",
              margin: 0,
              position: "absolute",
              left: 0,
              top: 0,
            }}
          />
        )) || <MissingImage>No Image For Now</MissingImage>}
      </ImageWrapper>

      <Content horizontalMode={horizontalMode}>
        <ProductName horizontalMode={horizontalMode}>
          {product.name}
        </ProductName>
        {rwd !== "desktop" && (
          <>
            {product.original_price !== product.price && (
              <OriginalPrice>NT: {product.original_price}</OriginalPrice>
            )}
            <Price horizontalMode={horizontalMode}>
              NT: ${product.price}起
            </Price>
          </>
        )}
      </Content>
      <Tag
        product={product}
        mobile={mobile}
        horizontalMode={horizontalMode}
        freeShipping={freeShipping}
        isPeriod={spec?.is_period}
        onItem={true}
      />
    </Wrapper>
  );
}

const LoadingDetail = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// const Image = styled.figure`
//   background-color: #e0e0e0;
//   background-image: url(${props => props.url});
//   background-size: cover;
//   background-position: center;
//   width: 100%;
//   height: 198px;
//   margin: 0;
// `;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  transition: box-shadow display 0.2s;

  ${props =>
    props.horizontalMode
      ? "height: 200px; width: 200px;"
      : "padding-top: 100%;"}
  & > .cover {
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &:hover {
    box-shadow: 0px 3px 10px -3px grey;
    & > .cover {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.38);
      & > .price-block {
        color: #fff;
        padding: 10px 25px;
        border: 2px solid #fff;
        font-size: 21px;
        margin: 0px;
      }
    }
  }
`;

const MissingImage = styled.figure`
  background-color: #e0e0e0;
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 8px;
  flex: 1;
`;

const ProductName = styled.div`
  font-size: 16px;
  text-align: ${props => (props.horizontalMode ? "left" : "center")};
  margin-bottom: 6px;
  padding: 0;
`;

const OriginalPrice = styled.div`
  color: #aaa;
  text-decoration: line-through;
  text-align: center;
  font-size: 10px;
  ${props => props.css || ""}
`;

const Price = styled.div`
  text-align: ${props => (props.horizontalMode ? "left" : "center")};
  color: ${Theme.colors.main};
  font-size: 12px;
  font-weight: 700;
  ${props => props.css || ""}
`;

const Wrapper = styled.a`
  display: ${props => (props.horizontalMode ? "flex" : "default")};
  width: ${props =>
    props.horizontalMode ? "auto" : props.isPad ? "46%" : "22%"};
  /* height: ${props => (props.horizontalMode ? "176px" : "auto")}; */
  margin-bottom: 55px;
  margin-top:  ${props => (props.horizontalMode ? "30px" : "0px")};
  padding: ${props => (props.horizontalMode ? "20px" : "0")};
  background-color: white;
  position: relative;
  cursor: pointer;
  border: ${props => (props.horizontalMode ? "1px solid #e2e2e2" : "0px")};

  &.delighter {
    transform: translateY(50px);
    transition: all 0.6s ease-out;
    opacity: 0;
  }
  &.delighter.started {
    transform: none;
    opacity: 1;
  }

  ${props => props.css}
`;

export default ProductItem;
