import React from "react";
import { SelectRow } from "../Components/ProductParam";
import * as Theme from "../Theme";
import SizeConfig from "../Components/SizeConfig";
import LocalProductConfig from "../Components/LocalProductConfig";

function ProductConfigsInDetail(props) {
  let {
    product,
    values,
    onConfigChange,
    extra,
    loadingPrice,
    requireDay,
    productMeta,
  } = props;
  return (
    <>
      <LocalProductConfig
        product={product}
        config={values}
        setConfig={onConfigChange}
      />

      <SizeConfig product={product} config={values} />

      {extra && extra.open_template_day && (
        <SelectRow style={{ alignItems: "baseline" }}>
          <label>開版日</label>
          <div className="select-options">{`星期 ${(!loadingPrice &&
            extra.open_template_day) ||
            "---"} `}</div>
        </SelectRow>
      )}

      <SelectRow style={{ alignItems: "baseline" }}>
        <label>備貨時間</label>
        <div className="select-options">
          <span
            style={{ color: Theme.colors.main, fontSize: 18 }}
          >{` ${(!loadingPrice && requireDay) || "---"} `}</span>
          <span style={{ fontSize: 12, padding: "0 0 0 5px" }}>
            {" "}
            天（審稿成功後隔日起算工作天，工作天不含假日及運送時間）
          </span>
        </div>
      </SelectRow>

      {productMeta && productMeta.file_upload && (
        <SelectRow style={{ alignItems: "baseline" }}>
          <label>傳檔說明</label>
          <div className="select-options">
            訂單成立後可上傳客製化檔案，並備註製作說明。
          </div>
        </SelectRow>
      )}
    </>
  );
}

export default ProductConfigsInDetail;
