import React, { Fragment } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

/* structure */
const mockdata = [
  { label: "感官觀點", link: "/articles" }, //root
  { label: "1", link: "#" }, //second
  { label: "2", link: "#" }, //third
];

export default function BreadCrumbs({
  routes = [],
  seperator = "/",
  style = {},
  color = "#aaa",
}) {
  return (
    <BreadCrumbsWrapper style={style} color={color}>
      {routes.map((r, idx) => (
        <>
          {idx === 0 ? null : <div className="sep">{seperator}</div>}
          {r.link ? (
            <Link className="route" to={r.link}>
              {r.label}
            </Link>
          ) : (
            <div className="route">{r.label}</div>
          )}
        </>
      ))}
    </BreadCrumbsWrapper>
  );
}

const BreadCrumbsWrapper = styled.div`
  display: flex;
  align-items: center;

  & > .route {
    cursor: pointer;
    color: ${props => props.color};
    &:hover {
      color: unset;
    }
  }

  & > .sep {
    color: ${props => props.color};
    margin: 0 10px;
  }
`;
