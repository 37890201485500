import React, { Component } from "react";
import styled from "styled-components";
import Constants from "../constants.js";

class ImageBackground extends Component {
  render() {
    let { src, extraStyle = {} } = this.props;
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundImage: `url(${src})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          ...extraStyle,
        }}
        onLoad={this._imgOnLoad}
        onError={this._imgOnError}
        {...this.props}
        src={`${Constants.mediaHost}${src}`}
      />
    );
  }

  _imgOnLoad = e => {};

  _imgOnError = e => {
    console.warn("ImageBackground onError", e);
  };
}

export default ImageBackground;
