import React from "react";
import styled from "styled-components";
import Image from "./Image";
import Constants from "../constants";
import * as Theme from "../Theme";
import * as Icon from "./Icon";

const TABS = {
  INTRO: 0,
  SPEC: 1,
  NOTICE: 2,
  TEMPLATE: 3,
};
class ProductBottomTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: TABS.INTRO,
    };
  }

  render() {
    const { tabIndex } = this.state;
    let { product } = this.props;

    let templates =
      product &&
      [...product.files].map(file => ({
        src: file.file,
        name: file.name,
      }));

    let introImages =
      product &&
      (product.image
        ? [...product.images.filter(image => image.name == "intro")]
        : product.images
      ).map(image => ({ src: image.image, link: image.link }));

    let specImages =
      product &&
      (product.image
        ? [...product.images.filter(image => image.name == "spec")]
        : product.images
      ).map(image => ({ src: image.image, link: image.link }));

    let noticeImages =
      product &&
      (product.image
        ? [...product.images.filter(image => image.name == "notice")]
        : product.images
      ).map(image => ({ src: image.image, link: image.link }));

    return (
      <Wrapper>
        <TabBar>
          <TabItem
            selected={tabIndex === TABS.INTRO}
            onClick={() => this.setState({ tabIndex: 0 })}
          >
            產品介紹
          </TabItem>
          <TabItem
            selected={tabIndex === TABS.SPEC}
            onClick={() => this.setState({ tabIndex: 1 })}
          >
            產品規格
          </TabItem>
          <TabItem
            selected={tabIndex === TABS.NOTICE}
            onClick={() => this.setState({ tabIndex: 2 })}
          >
            注意事項
          </TabItem>
          <TabItem
            selected={tabIndex === TABS.TEMPLATE}
            onClick={() => this.setState({ tabIndex: 3 })}
          >
            刀模下載
          </TabItem>
        </TabBar>

        <TabContent>
          {tabIndex === TABS.INTRO && (
            <div className="image-container">
              {introImages.map((img, idx) => (
                <Image
                  key={idx}
                  css={"width: 100%"}
                  src={img.src}
                  linkto={img.link}
                />
              ))}
            </div>
          )}

          {tabIndex === TABS.SPEC && (
            <div className="image-container">
              {specImages.map((img, idx) => (
                <Image
                  key={idx}
                  css={"width: 100%"}
                  src={img.src}
                  linkto={img.link}
                />
              ))}
            </div>
          )}

          {tabIndex === TABS.NOTICE && (
            <div className="image-container">
              {noticeImages.map((img, idx) => (
                <Image
                  css={"width: 100%"}
                  src={img.src}
                  linkto={img.link}
                  key={idx}
                />
              ))}
            </div>
          )}

          {tabIndex === TABS.TEMPLATE &&
            (() => {
              if (templates.length < 1) {
                return null;
              } else {
                return (
                  <TemplateSection>
                    <div className="title-row">
                      <div
                        className="item"
                        style={{ width: 40, textAlign: "center" }}
                      >
                        項次
                      </div>
                      <div
                        className="item"
                        style={{ width: 200, marginLeft: 30 }}
                      >
                        刀模名稱
                      </div>
                      <div
                        className="item"
                        style={{ width: 300, marginRight: 30, marginLeft: 10 }}
                      >
                        檔案名稱
                      </div>
                      <div
                        className="item"
                        style={{ width: 40, textAlign: "center" }}
                      >
                        下載
                      </div>
                    </div>
                    {templates.map((file, idx) => {
                      return (
                        <TemplateRow
                          style={{
                            margin: "15px 0",
                            display: "flex",
                            alignItems: "center",
                          }}
                          key={idx}
                        >
                          <div className="index">{`${idx + 1}`}</div>
                          <div className="names-container">
                            <p
                              className="name"
                              style={{ marginRight: 10, width: 200 }}
                            >{`${file.name}`}</p>
                            <p
                              className="name"
                              style={{
                                color: Theme.colors.blueGreen,
                                width: 300,
                              }}
                            >
                              {file.src.split("/").slice(-1)}
                            </p>
                          </div>
                          <div
                            className="btn"
                            onClick={e => {
                              e.stopPropagation();
                              this._downloadFile({
                                url: file.src,
                                fileName: file.src.split("/").slice(-1),
                              });
                              // this._downloadFile({
                              //   url: `${Constants.mediaHost}${eval(
                              //     'encodeURI("' + file.src + '")'
                              //   )}`,
                              //   fileName: file.src.split("/").slice(-1),
                              // });
                            }}
                          >
                            <img
                              src={"../../images/download.svg"}
                              width="30"
                              height="30"
                            />
                          </div>
                        </TemplateRow>
                      );
                    })}
                  </TemplateSection>
                );
              }
            })()}
        </TabContent>
      </Wrapper>
    );
  }

  // XXX
  _downloadFile = async ({ url, fileName }) => {
    let resp = await fetch(url);
    let blob = await resp.blob();
    let objUrl = URL.createObjectURL(blob);

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.id = "rev-download-url";
    a.style = "display: none";
    a.href = objUrl;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(objUrl);
    document.body.removeChild(a);
  };
}

const Wrapper = styled.div`
  background-color: white;
`;

const TabBar = styled.div`
  display: flex;
  border-bottom: 0.5px solid ${Theme.colors.unSelected};
`;

const TabItem = styled.div`
  padding: 10px;
  flex: 1;
  text-align: center;
  border-radius: 5px 5px 0px 0px;
  background-color: ${props =>
    props.selected ? Theme.colors.brown : "transparent"};
  color: ${props => (props.selected ? "#fff" : Theme.colors.main)};
  cursor: default;
  transition: all 0.2s linear;
  box-sizing: border-box;

  &:last-child {
    border-right: 0;
  }
`;

const TabContent = styled.div`
  min-height: 200px;
  padding: 20px;

  .image-container {
    margin: 0px 100px;
    width: 100% - 200px;

    @media screen and (max-width: 600px) {
      margin: 0px;
    }
  }
  @media screen and (max-width: ${Theme.breakpoints.lg}px) {
    padding: 0px;
  }
`;

const TemplateSection = styled.div`
  margin-top: 40px;
  & > .title-row {
    display: flex;
  }
  @media screen and (max-width: ${Theme.breakpoints.lg}px) {
    & > .title-row {
      justify-content: space-between;
      & > .item {
        max-width: 100px;
      }
    }
  }
`;

const TemplateRow = styled.div`
  & > .index {
    width: 40px;
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${Theme.colors.sub};
    @media screen and (max-width: ${Theme.breakpoints.lg}px) {
      height: 60px;
      padding: 0px 10px;
    }
  }
  & .names-container {
    display: flex;
    background-color: ${Theme.colors.sub};
    flex-direction: row;
    margin-left: 10px;
    padding: 10px 20px;
    @media screen and (max-width: ${Theme.breakpoints.lg}px) {
      flex-direction: column;
    }
  }
  & .name {
    margin: 0px;
    width: 200px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @media screen and (max-width: ${Theme.breakpoints.lg}px) {
      max-width: 200px;
    }
  }

  & > .btn {
    background-color: ${Theme.colors.main};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    cursor: pointer;
    @media screen and (max-width: ${Theme.breakpoints.lg}px) {
      height: 60px;
      padding: 5px;
    }
  }
`;

export default ProductBottomTabs;
