import React, { Component } from "react";
import styled from "styled-components";
import Lightbox from "react-images";
import Image from "./ImageBackground";
import Constants from "../constants.js";

class ProductImageLightbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
    };
  }

  _openLightbox = (index = 0) => {
    this.setState({
      lightboxIsOpen: true,
      currentImage: index,
    });
  };

  _closeLightbox = () => {
    this.setState({ lightboxIsOpen: false });
  };

  _gotoPrevious = () => {
    this.setState({ currentImage: this.state.currentImage - 1 });
    console.log("prev");
  };

  _gotoNext = () => {
    this.setState({ currentImage: this.state.currentImage + 1 });
    console.log("next");
  };

  _gotoImage = index => {
    this.setState({ currentImage: index });
  };

  render() {
    let { imagesArray, css, centerImage = false } = this.props;

    if (imagesArray.length === 0) {
      return null;
    }
    return (
      <Wrapper css={css}>
        <div className={`thumbnails-wrapper${centerImage ? " center" : ""}`}>
          {imagesArray &&
            imagesArray
              .filter(image => image.name == "top")
              .map((d, i) => {
                return (
                  <Image
                    key={i}
                    src={d.image}
                    extraStyle={{
                      width: 164,
                      height: 106,
                      cursor: "pointer",
                      flexShrink: 0,
                      marginRight: 10,
                    }}
                    onClick={() => this._openLightbox(i)}
                  />
                );
              })}
        </div>

        <Lightbox
          currentImage={this.state.currentImage}
          images={imagesArray
            .filter(image => image.name == "top")
            .map(d => ({ src: `${Constants.mediaHost}` + `${d.image}` }))}
          isOpen={this.state.lightboxIsOpen}
          onClickPrev={this._gotoPrevious}
          onClickNext={this._gotoNext}
          onClose={this._closeLightbox}
          showThumbnails={true}
          onClickThumbnail={this._gotoImage}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  & > .thumbnails-wrapper {
    display: flex;
    width: 100%;
    overflow: auto;
    justify-content: flex-start;
  }

  & > .thumbnails-wrapper.center {
    justify-content: center;
  }
  ${props => props.css}
`;

export default ProductImageLightbox;
